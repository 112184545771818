body {
  background-color: #f0f8ff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #ff6f61;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 15px;
}

.App-header h1 {
  color: white;
  margin: 0;
  font-size: 2.5em;
}

.header-icon {
  width: 100px;
  height: auto;
  border: 3px solid white;
  border-radius: 50%;
  padding: 10px;
  background-color: white;
  transition: transform 0.3s ease;
}

.header-icon:hover {
  transform: scale(1.1);
}

nav {
  margin-top: 10px;
}

nav a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.2em;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ff6f61;
  transition: background-color 0.3s ease;
}

nav a:hover {
  background-color: #ff9f80;
}

.intro {
  padding: 30px;
  background-color: #ffffff;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  text-align: left;
}

.intro p {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
}

.main {
  padding: 20px;
}

button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff9f80;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}

/* Additional CSS for Home component */
.home-container {
  padding: 80px;
}

.filters {
  margin-bottom: 20px;
}

.search-field, .filter-field {
  width: calc(33.333% - 20px);
  margin-right: 10px;
  margin-bottom: 10px;
}

.post-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.post-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 40px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.post-card h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #ff6f61; /* タイトルの色を変更 */
}

.post-card p {
  text-align: left; /* 左揃え */
  margin: 5px 0;
  color: #555; /* descriptionの色を変更 */
}

.details-link {
  display: inline-block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
}

.details-link:hover {
  text-decoration: underline;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination-button:hover {
  background-color: #ff9f80;
}

.contact-info {
  margin-top: 20px;
  text-align: center;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

/* Responsive design */
@media (max-width: 768px) {
  .App-header h1 {
    font-size: 2em;
  }

  nav a {
    font-size: 1em;
    margin: 0 10px;
  }

  .intro {
    padding: 20px;
  }

  .search-field, .filter-field {
    width: calc(100% - 20px);
    margin-right: 0;
  }

  .post-card {
    flex: 1 1 100%;
  }
}
