.footer {
  background-color: #f1f1f1;
  padding: 0px;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
}

.contact-info {
  margin-bottom: 10px;
}

.social-links p {
  display: inline-block;
  margin: 0;
}

.social-links a {
  color: #007bff;
  text-decoration: none;
  margin: 0 5px;
}

.social-links a:hover {
  text-decoration: underline;
}

.copyright {
  font-size: 0.9em;
  color: #888;
  margin-top: 10px;
}
