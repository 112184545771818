.post-details-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  padding-bottom: 100px; /* フッターの高さ分の余白を追加 */
}

.post-details-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff6f61;
}

.post-details-container p {
  margin: 10px 0;
  color: #555;
  text-align: left;
}

.nickname {
  font-size: 0.9em;
  color: #888;
  margin-top: 5px;
  text-align: right;
}

.chat-container {
  margin-top: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.chat-message {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.message-header strong {
  color: #ff6f61;
}

.timestamp {
  font-size: 0.9em;
  color: #888;
}

.message-body {
  color: #333;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.chat-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
}

.chat-textarea {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  resize: none;
  overflow: hidden;
  max-height: 150px;
}

.send-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-button:hover {
  background-color: #ff9f80;
}

.send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive design */
@media (max-width: 768px) {
  .post-details-container {
    padding: 15px;
    padding-bottom: 80px; /* モバイルでも余白を追加 */
  }

  .post-details-container h2 {
    font-size: 1.5em;
  }

  .chat-container {
    padding: 8px;
  }

  .chat-message {
    padding: 8px;
  }

  .chat-input {
    padding: 8px;
    margin: 8px 0;
  }

  .send-button {
    padding: 8px 16px;
  }

  .nickname {
    font-size: 0.8em;
  }
}
