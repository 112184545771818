body {
  background-color: #f0f8ff;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #ff6f61;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-content {
  display: flex;
  align-items: center;
  gap: 15px; /* スペースを広げる */
}

.App-header h1 {
  color: white;
  margin: 0;
  font-size: 2.5em;
}

.header-icon {
  width: 100px; /* アイコンのサイズをさらに大きくする */
  height: auto;
  border: 3px solid white; /* アイコンにボーダーを追加 */
  border-radius: 50%; /* アイコンを円形にする */
  padding: 10px; /* アイコンの内側にスペースを追加 */
  background-color: white; /* アイコンの背景色を追加 */
  transition: transform 0.3s ease; /* アイコンにホバー時のアニメーションを追加 */
}

.header-icon:hover {
  transform: scale(1.1); /* ホバー時にアイコンを拡大 */
}

nav {
  margin-top: 10px;
}

nav a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  font-size: 1.2em;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ff6f61;
  transition: background-color 0.3s ease;
}

nav a:hover {
  background-color: #ff9f80;
}

.intro {
  padding: 30px;
  background-color: #ffffff;
  border-bottom: 2px solid #ddd;
  margin-bottom: 20px;
  text-align: left; /* 左揃えに変更 */
}

.intro p {
  margin: 10px 0;
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
}

main {
  padding: 20px;
}

button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ff9f80;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1em;
}