.create-post-container {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
  padding-bottom: 100px; /* フッターの高さ分の余白を追加 */
}

.create-post-container h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #ff6f61;
}

.input-field, .textarea-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
}

.textarea-field {
  height: 100px;
  resize: vertical;
}

.create-button {
  background-color: #ff6f61;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #ff9f80;
}

/* Responsive design */
@media (max-width: 768px) {
  .create-post-container {
    padding: 15px;
    padding-bottom: 80px; /* モバイルでも余白を追加 */
  }

  .create-post-container h2 {
    font-size: 1.5em;
  }

  .input-field, .textarea-field {
    padding: 8px;
    margin: 8px 0;
  }

  .create-button {
    padding: 8px 16px;
  }
}
